import React from 'react'
import { graphql } from 'gatsby'
import { Block, Breadcrumbs, Layout } from '@components'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import BlockWrapper from '@pageBuilder/wrapper'

const Post = ({ data, pageContext }) => {
  const { moduleArea, title, path, seoMetaTags } = data.post

  return (
    <Layout>
      <HelmetDatoCms defer={false} seo={seoMetaTags} />
      {pageContext?.breadcrumbs && (
        <Block gutters>
          <Breadcrumbs breadcrumbs={pageContext?.breadcrumbs} slim />
        </Block>
      )}
      <BlockWrapper blocks={moduleArea} />
    </Layout>
  )
}

export const query = graphql`
  query postById($id: String!) {
    post: datoCmsPost(id: { eq: $id }) {
      id
      slug
      moduleArea {
        ... on DatoCmsAccordion {
          ...Accordion
        }
        ... on DatoCmsButton {
          ...Button
        }
        ... on DatoCmsCardGrid {
          ...CardGrid
        }
        ... on DatoCmsContent {
          ...Content
        }
        ... on DatoCmsCta {
          ...Cta
        }
        ... on DatoCmsImage {
          ...Image
        }
        ... on DatoCmsImageWithContent {
          ...ImageWithContent
        }
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }

    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
export default Post
